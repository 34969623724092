import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import store from "@/store";

Vue.use(VueRouter)
const userGroup = sessionStorage.getItem("group")
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            isAuth: true,
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/fb/commande-coquillage',
        name: 'FbShellfishPurchase',
        component: () => import(/* webpackChunkName: "ShellfishPurchase" */ '../views/fb/FbShellfishPurchase.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/fb/marketing',
        name: 'FbMarketing',
        component: () => import(/* webpackChunkName: "FbMarketing" */ '../views/fb/FbMarketing.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/fb/probleme-qualite',
        name: 'FbQuality',
        component: () => import(/* webpackChunkName: "FbQuality" */ '../views/fb/FbQuality.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/gb/rapport-qualite',
        name: 'GBQuality',
        component: () => import(/* webpackChunkName: "GbQuality" */ '../views/gb/GbQuality.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [5]
        }
    },
    {
        path: '/fb/packing-list',
        name: 'FbPackingList',
        component: () => import(/* webpackChunkName: "FbQuality" */ '../views/fb/FbPackingList.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/fb/cours',
        name: 'FbStockPrice',
        component: () => import(/* webpackChunkName: "FbStockPrice" */ '../views/fb/FbStockPrice.vue'),
        meta : {
            requiredAuth : true,
            authorizedGroup : [2]
        }
    },
    {
        path: '/fb/chrono',
        name: 'FbChrono',
        component: () => import(/* webpackChunkName: "FbChrono" */ '../views/fb/FbChrono'),
        meta : {
            requiredAuth : true,
            authorizedGroup : [2]
        }
    },
    {
        path: '/fb/dashboard',
        name: 'BusinessDashboard',
        component: () => import(/* webpackChunkName: "BusinessDashboard" */ '../views/fb/BusinessDashboard'),
        meta : {
            requiredAuth : true,
            authorizedGroup : [2]
        }
    },
    {
        path: '/precommande',
        name: 'CustomerPreorder',
        component: () => import(/* webpackChunkName: "FbPreorder" */ '../views/customer/CustomerPreorder.vue'),
        meta : {
            requiredAuth : true,
            authorizedGroup : [6]
        }
    },
    {
        path: '/mes-precommande',
        name: 'CustomerMyPreorder',
        component: () => import(/* webpackChunkName: "FbPreorder" */ '../views/customer/CustomerMyPreorder.vue'),
        meta : {
            requiredAuth : true,
            authorizedGroup : [6]
        }
    },
    {
        path: '/fb/preorder',
        name: 'FbPreorderSettings',
        component: () => import(/* webpackChunkName: "FbPreorderSettings" */ '../views/fb/FbPreorderSettings.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },{
        path: '/fb/admin/preorder',
        name: 'FbPreorderAdmin',
        component: () => import(/* webpackChunkName: "FbPreorderAdmin" */ '../views/fb/FbPreorderAdmin.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/fb/ecommerce/product',
        name: 'EcommerceProduct',
        component: () => import(/* webpackChunkName: "EcommerceProduct" */ '../views/ecommerce/EcommerceProduct.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [2]
        }
    },
    {
        path: '/produit/:id',
        name: 'Template',
        props: true,
        component: function () {
            return import(/* webpackChunkName: "produit" */ '../views/ecommerce/Template.vue')
        }
    },
    {
        path: '/dc/planning',
        name: 'DispatchCenterPlanning',
        component: () => import(/* webpackChunkName: "DispatchCenterPlanning" */ '../views/dc/DispatchCenterPlanning.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [4]
        }
    },
    {
        path: '/dc/cerclage',
        name: 'DispatchCenterStrapping',
        component: () => import(/* webpackChunkName: "DispatchCenterPlanning" */ '../views/dc/DispatchCenterStrapping.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [4]
        }
    },
    {
        path: '/dc/roadmap',
        name: 'DispatchCenterRoadmap',
        props: true,
        component: () => import(/* webpackChunkName: "BHPSupplier" */ '../views/dc/DispatchCenterRoadmap.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [4]
        }
    },
    {
        path: '/dc/pickup',
        name: 'DispatchCenterPickupTour',
        component: () => import(/* webpackChunkName: "BhpPickupTour" */ '../views/dc/DispatchCenterPickupTour.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [4]
        }
    },
    {
        path: '/fournisseurs/affichage',
        name: 'SupplierDisplay',
        component: () => import(/* webpackChunkName: "SupplierDisplay" */ '../views/supplier/SupplierDisplay.vue'),
        meta: {
            requiredAuth: true,
            authorizedGroup: [3]
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const initialize = () => new Promise((resolve, reject) => {
    resolve(store.dispatch('auth/initializeStore'));
})

router.beforeEach((to, from, next) => {
    initialize().then(() => {
        if (to.matched.some(record => record.meta.requiredAuth)) {
            if (!store.getters["auth/isAuth"]) {
                next({name: 'Login'})
            } else {
                const group = store.getters["auth/getGroup"]
                if (to.matched.some(record => record.meta.requiredAuth && record.meta.authorizedGroup.includes(group.id))) {
                    next()
                } else {
                    next(from)
                }
            }
        } else {
            next()
        }
    }).catch(
        error => console.log(error)
    )
})

export default router

