<template>
  <div class="LoginVue">
    <img src="../assets/image/gbi_icone_header.png" class="mt-1 pt-5 mb-3">
    <div class="justify-content-md-center w-100">
      <h1 class="text-center w-100 p-4">CONNECTEZ-VOUS A L'INTRANET DU GROUPE BOUTRAIS</h1>
      <div class="md-5 p-3 justify-content-md-center">
        <form v-on:submit.prevent="login" class="d-flex align-items-center flex-column ">
          <div class="form-group w-100">
            <input id="user" v-model="loginForm.email"
                   class="form-control rounded-pill " name="username"
                   placeholder="ADRESSE MAIL" type="text"/>
          </div>
          <br>
          <div class="form-group w-100 ">
            <input id="pass" v-model="loginForm.password"
                   class="form-control rounded-pill" name="password"
                   placeholder="MOT DE PASSE" type="password"/>
          </div>
          <br>
          <button class="btn btn-lg btn-dark btn-block rounded-pill" type="submit" v-on:click="login()">SE CONNECTER
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginVue",
  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      }
    }
  },
  methods: {
    login: async function() {
      const formData = this.loginForm;
      await this.$store.dispatch('auth/login', formData)
    },
  }
}

</script>

<style scoped>
button {
  background-color: #25378D;
  width: 20%;
  margin-left: 35%;
  margin-right: 35%;
  margin-bottom: 17%;
}

input {
  padding: 25px 25px;
  color: #878FA1;
  width: 40%;
  margin-left: 30%;
}

.LoginVue {
  background-image: url(../assets/image/gbi_fond.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: border-box;
}

@media (max-width: 830px) {
  button, input {
    font-size: 0.8em;
  }

  button {
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
  }

  h1 {
    font-size: 1.2em;
  }
}

@media (max-width: 2600px) {
  .LoginVue {
    background-size: 100%;
  }
}
</style>