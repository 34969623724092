import {getAPI} from "@/services/axios-api";
import router from "@/router";

const state = () => ({
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    partner: null,
    group: null,
    warehouse: null,
    try: false,
    userName: null,
});

const getters = {
    getAccessToken: function (state) {
        return state.accessToken;
    },
    getGroup: function (state) {
        return state.group;
    },
    getWarehouse: function (state) {
        return state.warehouse;
    },
    getPartner: function (state) {
        return state.partner;
    },
    getUserName: function (state) {
        return state.userName;
    },
    isAuth: function (state) {
        return state.isAuthenticated;
    }
};

const actions = {
    initializeStore: async function (context) {
        context.commit('initializeStore')
        if (!context.state.try) {
            await context.dispatch('getInfos')
        }
    },
    login: async function (context, credentials) {
        return new Promise(async (resolve, reject) => {
            await getAPI
                .post('/account/login/', credentials)
                .then(response => {
                    context.commit('setToken', {
                        access: response.data.tokens.access,
                        refresh: response.data.tokens.refresh,
                    })
                })
                .catch(err => {
                    reject(err)
                })
            await context.dispatch('getInfos')
                .then(r => {
                    switch (context.state.group.id) {
                        case 1:
                            router.push({name: 'FbShellfishPurchase'}).catch();
                            // router.push({name: 'Home'});
                            break;
                        case 2:
                            router.push({name: 'FbShellfishPurchase'}).catch();
                            break;
                        case 3:
                            router.push({name: 'SupplierDisplay'}).catch();
                            break;
                        case 4:
                            router.push({name: 'DispatchCenterPlanning'}).catch();
                            break;
                        case 5:
                            router.push({name: 'GBQuality'}).catch();
                            break;
                        case 6:
                            router.push({name: 'CustomerPreorder'}).catch();
                            break;
                    }
                })
        })
    },
    getInfos: async function (context) {
        await getAPI
            .get('account/user/infos/')
            .then(response => {
                context.commit('setInfos', response.data)
            })
            .catch(error => {
                context.commit('setTry', true)
            })
    },
    refreshToken: function ({commit}, access) {
        commit('refreshToken', access)
    },
    removeToken: function ({commit}) {
        commit('removeToken')
    },
    logout: function (context) {
        context.commit('removeToken');
        context.dispatch('socket/closeSocket', null, {root: true});
    },
};

const mutations = {
    setTry: function (state, bool) {
        state.try = bool
    },
    initializeStore: function (state) {
        const access = sessionStorage.getItem("access");
        const refresh = sessionStorage.getItem("refresh");

        if (access && refresh) {
            state.accessToken = access;
            state.refreshToken = refresh;
            state.isAuthenticated = true;
            getAPI.defaults.headers.common['Authorization'] = state.accessToken
        } else {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            getAPI.defaults.headers.common['Authorization'] = ""
        }
    },
    setToken: function (state, tokens) {
        state.accessToken = tokens.access;
        state.refreshToken = tokens.refresh;
        state.isAuthenticated = true;
        sessionStorage.setItem('access', tokens.access);
        sessionStorage.setItem('refresh', tokens.refresh);
        getAPI.defaults.headers.common['Authorization'] = state.accessToken
    },
    setInfos: function (state, infos) {
        state.partner = infos.partner;
        state.group = infos.group;
        state.warehouse = infos.warehouse;
        state.userName = infos.user_name;
    },
    refreshToken: function (state, access) {
        state.isAuthenticated = true;
        state.accessToken = access;
        getAPI.defaults.headers.common['Authorization'] = state.accessToken
    },
    removeToken: function (state) {
        state.accessToken = null
        state.refreshToken = null
        state.isAuthenticated = false
        state.partner = null
        state.group = null
        sessionStorage.clear()
        getAPI.defaults.headers.common['Authorization'] = ""
    }
};

export default {
    namespaced: true, state, getters, actions, mutations,
};