<template>
 <div class="LogoutVue">
  </div>
</template>

<script>
export default {
    created () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'Login' }).catch()
        })
        .catch(err => {
            console.log(err)
          })
    }
  }
</script>

<style scoped>
</style>