import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {BootstrapVue, BootstrapVueIcons, IconsPlugin} from 'bootstrap-vue'
import './assets/custom.scss'
import {library} from '@fortawesome/fontawesome-svg-core'
import VCalendar from 'v-calendar';
import Multiselect from 'vue-multiselect';
import setupInterceptors from './services/setupInterceptors';


import {
  faAlignJustify, faBan,
  faCheckCircle,
  faCircle,
  faComments,
  faDollyFlatbed,
  faExclamationCircle,
  faExclamationTriangle,
  faHome,
  faKey,
  faList,
  faPowerOff,
  faPrint,
  faSearch,
  faTags,
  faTruck,
  faUser,
  faUserPlus,
  faWrench,
  faWarning
} from '@fortawesome/free-solid-svg-icons'
import {faFirstdraft} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faUserPlus, faTags, faTruck, faDollyFlatbed, faPrint, faCheckCircle, faKey, faSearch, faExclamationCircle,
    faFirstdraft, faCircle, faAlignJustify, faHome, faWrench, faUser, faPowerOff, faComments, faList, faExclamationTriangle,
    faBan, faWarning)
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)

Vue.use(
    BootstrapVue,
    BootstrapVueIcons,
    IconsPlugin,)


Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.config.productionTip = false

setupInterceptors(store)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
