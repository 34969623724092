export class TokenService {
    static getLocalRefreshToken() {
        return sessionStorage.getItem('refreshToken');
    }

    static getLocalAccessToken() {
        return sessionStorage.getItem('accessToken');
    }

    static updateLocalAccessToken(token) {
        sessionStorage.setItem('accessToken', JSON.stringify(token))
    }

}

export default new TokenService();