import {getAPI} from "@/services/axios-api";
import router from "@/router";

const state = () => ({
    listSols: [],
    listUom: [],
});

const getters = {
    getListSols: function (state) {
        return state.listSols;
    },
    getListUom: function (state) {
        return state.listUom;
    },
};

const actions = {
    async getSol (context, params) {
            await getAPI
                .get('/sale/order/line/', {
                    params: {
                        'categ': params.categ.toString(),
                        'date': params.date,
                        'state': params.state.toString(),
                        'tour': params.tour ? params.tour : '',
                    }
                }).then(response => {
                    console.log(response.data.length)
                    context.commit('setListSols', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
    },
    getSupplierSol: function (context, params) {
        return new Promise(async (resolve, reject) => {
            await getAPI
                .get('/sale/order/line/supplier/', {
                    params: {
                        'supplier_id': params.id,
                        'date': params.date,
                    }
                }).then(response => {
                    context.commit('setListSols', response.data)
                })
                .catch(error => {
                    console.log(error)
                })
        })
    },
    changeSOL: async function (context, dict, test) {
        let change = true
        console.log(test)
        await getAPI
            .patch('/sale/order/line/' + dict.id + '/', dict.item, {
                params: {
                    change: true,
                }
            })
            .then(response => {
                console.log(response)
            })
    },
    getUom: async function (context) {
        await getAPI
            .get('/product/uom/')
            .then(response => {
                context.commit('setListUom', response.data)
            })
    },
};

const mutations = {
    setListSols: function (state, data) {
        data.forEach(e => {
            if (e.django_line.product_uom_d === 0) {
                e.django_line.product_uom_d = e.product_uom;
            }
        })
        state.listSols = data
    },
    setListUom: function (state, data) {
        state.listUom = data
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};