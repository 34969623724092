import {getAPI} from "@/services/axios-api";

const state = () => ({
    templates: [],
    template: null,
});

const getters = {
    getTemplates: function (state) {
        return state.templates
    },
    getTemplate: function (state) {
        return state.template
    }
};

const actions = {
    searchProduct: async function (context, search) {
        await getAPI
            .get('/product/ecommerce/template/', {
                params: {
                    search: search,
                }
            }).then(response => {
                context.commit('setTemplates', response.data);
            })
    },
    getUniqueTemplate: async function (context, templateId) {
        await getAPI
            .get('product/ecommerce/template/' + templateId + '/')
            .then(response => {
                context.commit('setTemplate', response.data);
            })
    }
}
const mutations = {
    setTemplates: function (state, data) {
        state.templates = data;
    },
    setTemplate: function (state, data) {
        state.template = data;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};