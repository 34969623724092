import axios from "axios";

const getAPI = axios.create({
    // baseURL: 'http://127.0.0.1:8000/',
    // baseURL: 'http://devapi.groupeboutrais.fr/',
    baseURL: 'https://admin.groupeboutrais.fr/',
    timeout: 60000,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X_CSRFTOKEN'
})

export {getAPI}

