import Vue from 'vue'
import Vuex from 'vuex'
import {getAPI} from '../services/axios-api'
import auth from "@/store/modules/auth";
import error from "@/store/modules/error";
import orderlines from "@/store/modules/orderlines";
import supplier from "@/store/modules/supplier";
import socket from "@/store/modules/socket";
import product from "@/store/modules/product";

Vue.use(Vuex)

const store =  new Vuex.Store({
  state: {
    APIData: '',
    url: 'wss://admin.groupeboutrais.fr',
    isLoading: false,
    endpoints: {
      obtainJWT: getAPI
    }
  },
  mutations: {
    setIsLoading(state, status) {
      state.isLoading = status
    },
  },
  actions: {
  },
  modules: {
    auth: auth,
    error: error,
    orderlines:  orderlines,
    supplier: supplier,
    socket: socket,
    product: product,
  }
})

export default store