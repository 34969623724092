<template>
  <div id="app">
    <ErrorDisplay v-if=""></ErrorDisplay>
    <router-view :key="$route.path"/>
  </div>
</template>

<script>

import {getAPI} from "./services/axios-api";
import ErrorDisplay from "@/components/ErrorDisplay";

export default {
  name: 'app',
  components: {ErrorDisplay},
  data() {
    return {
      time: 0
    }
  },
  methods: {},
  beforeCreate() {
  },
  computed: {}
}

</script>
<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
