import {getAPI} from "@/services/axios-api";
import router from "@/router";

const state = () => ({
    listSuppliers: [],
    listPickups: [],
});

const getters = {
    getSuppliers: function (state) {
        return state.listSuppliers
    },
    getPickups: function (state) {
        return state.listPickups
    }
};

const actions = {
    loadSuppliers: async function (context,) {

        await getAPI
            .get('/partner/', {
                params: {
                    supplier: true,
                }
            })
            .then(response => {
                context.commit('setListSuppliers', response.data)
            })
    },
    loadPickup: async function (context) {
        await getAPI
            .get('/django/pickup/', {
                params: {
                    params: true,
                }
            })
            .then(response => {
                context.commit('setListPickups', response.data)
            })
    }
};

const mutations = {
    setListSuppliers: function (state, data) {
        state.listSuppliers = data
    },
    setListPickups: function (state, data) {
        state.listPickups = data
    }
};

export default {
    namespaced: true, state, getters, actions, mutations,
};