import {getAPI} from "@/services/axios-api";
import router from "@/router";


const state = () => ({
    socket: null,
    //url: 'ws://localhost:8000',
    // url: 'ws:http://devapi.groupeboutrais.fr/',
    url: 'wss://admin.groupeboutrais.fr',
});

const getters = {
    getSocket: function (state) {
      return state.socket
    },
};

const actions = {
    setSocket: function (context) {
        context.commit('setSocket');
    },
    closeSocket: function (context) {
        context.commit('closeSocket')
    }
}
const mutations = {
    setSocket: function (state) {
        state.socket = new WebSocket(state.url + `/ws/data/`)
    },
    closeSocket: function (state) {
        if (state.socket) state.socket.close();
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};