import {getAPI} from "@/services/axios-api";

const state = () => ({
    error: false,
    message: null,
    response: null,
});

const getters = {
    getMessage: function (state) {
        return state.message;
    },
    getResponse: function (state) {
        return state.response;
    },
    hasErrors: function (state) {
        return state.error;
    }
};

const actions = {
    setError: function ({commit}, error) {
        commit('setError', error);
    },
};

const mutations = {
    setError: function (state, error) {
        state.message = error.message;
        state.response = error.response;
        state.error = true;
    },
};

export default {
    namespaced: true, state, getters, actions, mutations,
};